// 
// user.scss
// Use this to write your custom SCSS
//

.form-control[readonly] {
  background-color: $input-disabled-bg;
  box-shadow: none !important;
  cursor: not-allowed;
}

.img-fill {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

blockquote {
  border-left: 3px solid $primary;
  padding-left: 1rem;
}

.bg-wave{
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 11vw;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 1440 158' viewBox='0 0 1440 158'%3E%3Cpath d='M1440 117c-387-174-764 94-1142 27C163 120 74 83 0 0v158h1440v-41z' style='fill-rule:evenodd%3Bclip-rule:evenodd%3Bfill:%23fff'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    bottom: -1px;
  }
}

.swiper-button-disabled{
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.text-gradient-primary {
  background: linear-gradient(to right, $primary, #f1594a, #b92020);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 3.5rem;
  background-position: right 1rem center, center right 2rem;
}

.btn-twitter{
  svg{
    transition: fill .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    fill: var(--si-btn-color);
  }
  &:hover, &:focus, &:active{
    svg{
      fill: #FFF;
    }
  }
}

.promo-bar {
  background-color: #A30505;
  color: #FFFFFF;
  text-align: center;
}

.promo-bar .promo-text p {
  margin: 0
}

.promo-bar .promo-text a {
  color: $secondary;
  text-decoration: underline;
}
